import React, { useEffect, useState } from 'react'
import { Link } from 'gatsby'

import Div from '../../elements/Div'
import Header from '../../elements/Header'
import Nav from '../../elements/Nav'
import H1 from '../../elements/H1'

import Layout from '../../components/layout'
import Logo from '../../components/Logo'
import { Grid, GridItemWrap } from '../../components/color/grid'

import { db } from '../../lib/client'

export default () => {
  const [colors, setColors] = useState([])

  useEffect(() => {
    let savedColors = []
    try {
      savedColors = JSON.parse(localStorage.getItem('colorVotes')) || []
    } catch (e) {}

    const retrieveAllColors = async () => {
      const colorFetches = savedColors
        .map(color => db.doc(`color/${color.id}`))
        .map(ref => ref.get())

      const colors = await Promise.all(colorFetches)

      setColors(colors.map(color => ({ id: color.id, ...color.data() })))
    }

    retrieveAllColors()
  }, [])

  return (
    <Layout>
      <Nav>
        <Link to="/">
          <Div p={3}>
            <Logo />
          </Div>
        </Link>
      </Nav>

      <Header px={3} py={3} textAlign="center">
        <H1 my={0} fontSize={2}>
          Colors
        </H1>
      </Header>
      <Div maxWidth="72em" mx="auto">
        <Grid>
          {colors.map(color => (
            <GridItemWrap key={color.id}>
              <Link
                to={`/color/share?id=${color.id}`}
                css={{
                  cursor: 'pointer',
                  display: 'block',
                  height: '100%',
                  width: '100%'
                }}
              >
                <div
                  css={{
                    backgroundColor: color.data,
                    height: '100%',
                    width: '100%'
                  }}
                />
              </Link>
            </GridItemWrap>
          ))}
        </Grid>
      </Div>
    </Layout>
  )
}
